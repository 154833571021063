var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1sBxLwOQK3r2g32k7znzb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/desmos";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: process.env.NEXT_PUBLIC_SENTRY_DSN ? process.env.NEXT_PUBLIC_RELEASE : '',
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_DSN ? 1.0 : 0,
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,
});
